import { useContext } from 'react'
import { HeaderCheckout, MainPublic, PreRegistrationForm } from '~/components/index'
import { LoadingRing } from '~/components/molecules'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { useScreen } from '~/hooks/useScreen'

const defaultScreen = (
  <PreRegistrationForm
    title="Boas-vindas"
    subTitle="Informe seu CPF para recuperar um pedido ou conheça a Principia."
    variantButtonText="Conhecer a Principia"
    isButtonGroup
  />
)

export default function IsaIndex() {
  const { isRedirecting, isHeaderCheckoutShown } = useContext(CheckoutContext)

  const { currentScreen } = useScreen({
    defaultScreen,
  })

  if (isRedirecting) {
    return <LoadingRing />
  }

  return (
    <MainPublic>
      {typeof window !== 'undefined' && isHeaderCheckoutShown && <HeaderCheckout />}
      {typeof window !== 'undefined' && currentScreen}
    </MainPublic>
  )
}
